import fetcher from "@/fetcher";
import { Session } from "@/nextlib/session";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useSWR, { mutate } from "swr";


// allows to mutate and revalidate
export const mutateSession = (data?: Session, shouldRevalidate?: boolean) =>
  mutate("/api/auth/session", data, shouldRevalidate);

/**
 * Thin wrapper hook around `useSWR` to provide automatic
 * redirection to login page is configured
 *
 * @param param0
 * @returns
 */
export default function useSWRSession({
  redirectTo = "",
  redirectIfFound = false,
} = {}) {
  // check if provider is still loading (avoid redirecting)
  const { data: session, isValidating: isLoading } = useSWR<Session>(
    "/api/auth/session",
    fetcher,
    {
      onErrorRetry(error, key, config, revalidate, { retryCount = 0 }) {
        // Never retry on 404.
        // TODO: error.status === 401 <- should not retry but
        // something wrong with auth
        if (error.status === 404 || error.status === 401) {
          return;
        }
        // configure max retry
        if (retryCount >= 3) {
          return;
        }
        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },

      // refreshes every 10 min
      refreshInterval: 10 * 60 * 1000,
      // valid login
      revalidateOnFocus: false,
      // validate on reconnect
      revalidateOnReconnect: true,
    }
  );

  const router = useRouter();
  const hasSession = Boolean(session?.email);

  useEffect(() => {
    if (!redirectTo || isLoading) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasSession) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasSession)
    ) {
      router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, hasSession, isLoading]);

  return {
    isLoading,
    session: session ?? null,
  };
}
