import flyd from "flyd";

import * as store from "store";

import { StoreTy } from "@/store/ty";
import { bootstrapStore } from "@/store/helper";
import { setupTrigger } from "./trigger";

const initialState = bootstrapStore();

/**
 * `updateStream` - contains partial store update that updates the
 * full state, which is captured in `stateStream`
 */
const updateStream = flyd.stream().of(initialState) as flyd.Stream<
  Promise<Partial<StoreTy>>
>;

/**
 * canonical view of the current world state
 */
export const stateStream = store.mkStateStream<StoreTy>(
  updateStream
) as flyd.Stream<StoreTy>;

export const trigger = setupTrigger(updateStream, stateStream);

export function onStoreUpdate(
  path: string,
  cb: (_: store.JSONPathStream<StoreTy>) => void
) {
  const updateStream = store.mkStateChangeStreamByJSONPathWithDigest<StoreTy>(
    stateStream
  )(path);

  // TODO: fix the updatestream type here
  return flyd.on(cb, updateStream as any);
}

if (process.env.NEXT_PUBLIC_DEBUG_STORE) {
  if (typeof window !== "undefined") {
    (<any>window).__store = stateStream;
  }
}
