import { queryParam2PersonaFiler } from "@/model/personaFilter";
import { validateTimeFilterQueryParam } from "@/model/timeFilter";
import { FilterToolbar, StoreTy } from "@/store/ty";
import { ParsedUrlQuery, parse } from "querystring";

const WEB_STORAGE_KEY = "store/v1";

// TODO: allow serialization of store state
export function bootstrapStore(noLocalStorage?: boolean): Promise<StoreTy> {
  const savedStore = getLocalStorage(WEB_STORAGE_KEY);

  if (savedStore && !noLocalStorage) {
    return Promise.resolve(JSON.parse(savedStore));
  }

  const filterToolbar =
    typeof window !== "undefined"
      ? restoreFilterStateFromQueryParams(
          parse(window.location.search.substring(1))
        )
      : {};

  return Promise.resolve({
    userAccount: {},
    ui: {
      convoLayout: "1col",
      filterToolbar,
    },
  });
}

export function saveStore(store: StoreTy): void {
  setLocalStorage(WEB_STORAGE_KEY, JSON.stringify(store));
}

export function restoreFilterStateFromQueryParams(
  query: ParsedUrlQuery
): FilterToolbar {
  const ft: FilterToolbar = {};

  if (query.persona) {
    ft.personaFilter = queryParam2PersonaFiler(query);
  }

  if (query.rAfter) {
    ft.timeFilter = {
      selectedInterval: validateTimeFilterQueryParam(query.rAfter as string),
    };
  }

  return ft;
}

function getLocalStorage(key: string): string | null {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(key);
  }
  return null;
}

function setLocalStorage(key: string, val: string) {
  if (typeof localStorage !== "undefined") {
    return localStorage.setItem(key, val);
  }
  throw new Error("invalid use");
}
