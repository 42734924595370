import { Logger, ILogObj } from "tslog";

export function getLogger(name: string) {
  const isProd = process.env.NODE_ENV === "production";
  const log: Logger<ILogObj> = new Logger({
    name,
    // prod = 4: warn, otherwise 2: debug
    minLevel: isProd ? 4 : 2,
    hideLogPositionForProduction: isProd,
  });

  const proxyLog = new Proxy(log, {
    get(target, prop, receiver) {
      if (prop.toString().startsWith("sample_")) {
        // sampling log
        const method = prop.toString().substring("sample_".length);
        // @ts-ignore
        const value: any = target[method];
        if (value instanceof Function) {
          return function (...args: any[]) {
            // first param is sampling rate from 0 to 1
            const sampleRate = args[0];
            if (Math.random() > sampleRate) {
              return;
            }
            return value.apply(target, args.slice(1));
          };
        }
      }

      // console.log("The Proxy get trap");
      const value: any = (target as any)[prop];
      if (value instanceof Function) {
        // @ts-ignore
        return function (...args) {
          // @ts-ignore
          return value.apply(this === receiver ? target : this, args);
        };
      }

      return value;
    },
  });

  return proxyLog;
}
