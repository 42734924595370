import React from "react";

interface LoadingProps {
  msg?: string;
  textOnly?: boolean;
}

export default function Loading(props: LoadingProps) {
  if (props.msg) {
    return <span>{props.msg}</span>;
  }

  if (props.textOnly) {
    return <h1 className="text-2xl">loading...</h1>;
  }

  return (
    <div className="m-auto w-100v h-100v flex justify-around items-center">
      <h1 className="text-4xl">loading...</h1>
    </div>
  );
}
