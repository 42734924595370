import fetch from "unfetch";

export default (url: string) =>
  fetch(url).then((res) => {
    if (res.ok) {
      return res.json();
    }
    const error = new Error(
      "An error occurred while fetching the data."
    ) as Error & { status: number };
    error.status = res.status;
    throw error;
  });

export const jsonAPI = (method: "POST" | "PUT") =>
  async function <T, V>(url: string, jsonBody: T): Promise<V> {
    const res = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonBody),
    });

    if (res.ok) {
      if (res.status === 202) {
        // TODO: consolidate how api works
        // 202 we do not
        return Promise.resolve({} as V);
      }
      return res.json();
    }

    const error: any = new Error("An error occurred while fetching the data.");
    error.status = res.status;
    throw error;
  };

export const post = jsonAPI("POST");
export const put = jsonAPI("PUT");
