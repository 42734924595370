import React, { useEffect, useMemo } from "react";
import { createContext, useState } from "react";

import rosetta from "rosetta";

import locale from "@/i18n/locale";

export const I18n = function (rosettaOpts?: Record<string, any>) {
  const r = rosetta(rosettaOpts);

  return {
    /**
     * Triggers a render cycle
     */
    _onUpdate() {},
    /**
     * Retrieve a translation segment for the active language
     */
    t(k: string, params?: object, other?: unknown) {
      const res = r.t.apply(r, arguments as any);
      if (res === "") {
        return `<${k}>`;
      }
      return res;
    },
    /**
     * Get the table of translations for a language
     */
    table: r.table,
    /**
     * Define or extend the language table
     */
    set: r.set,
    /**
     * Set a locale or returns the active locale
     */
    locale(locale: string) {
      if (locale === undefined) {
        return r.locale();
      }
      const activelocale = r.locale(locale);
      this._onUpdate();
      return activelocale;
    },
    /**
     * Returns an i18n instance that treats number values as pluralization
     */
    withPlural(
      pluralRulesOptions: { type: "ordinal" | "cardinal" | undefined } = {
        type: "ordinal",
      }
    ) {
      const PR = new Intl.PluralRules(r.locale(), pluralRulesOptions);
      return (key: string, params: Record<string, any>, ...args: any) => {
        Object.keys(params).map((k) => {
          if (typeof params[k] === "number") {
            let pkey = PR.select(params[k]);
            params[k] = this.t(`${k}.${pkey}`);
          }
        });
        return this.t(key, params, ...args);
      };
    },
  };
};

export const defaultI18n = I18n(locale);

// Build default context and load all the locales
export const I18nContext: React.Context<ReturnType<
  typeof I18n
>> = createContext(defaultI18n);

export default function I18nProvider({
  children,
  locale = "en",
  lngDict,
  i18nInstance,
}: {
  children?: React.ReactNode;
  locale: string;
  lngDict: Record<string, any>;
  i18nInstance?: ReturnType<typeof I18n>;
}) {
  const [, setTick] = useState(0);

  const i18n = useMemo(() => {
    const instance = i18nInstance ?? defaultI18n;
    instance._onUpdate = () => setTick((tick) => tick + 1);
    instance.set(locale, lngDict);
    instance.locale(locale);
    return instance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nInstance]);

  useEffect(() => {
    i18n.set(locale, lngDict);
    i18n.locale(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, lngDict]);

  return (
    <I18nContext.Provider value={{ ...i18n }}>{children}</I18nContext.Provider>
  );
}
