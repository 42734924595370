export const TIME_FILTER_INTERVALS = [
  "newest",
  // "past_day",
  "this_week",
  "this_month",
];

export function timeFilter2RAfter(tf: string): string {
  if (tf === "this_week") {
    return "-168h";
  } else if (tf === "this_month") {
    return "-720h";
  }

  // by default, realtime focuses on last 24h
  return "-24h";
}

export function validateTimeFilterQueryParam(s: string): string {
  const idx = TIME_FILTER_INTERVALS.indexOf(s);
  if (idx === -1) {
    return "";
  }
  return s;
}
