import { PersonaFilter } from "@/store/ty";
import { ParsedUrlQuery } from "querystring";

export function personaFilterToQueryParam(pf: PersonaFilter) {
    return Object.entries(pf.filter ?? {}).map(([k, v]) => {
        if (!v) {
            return "~" + k;
        }
        return k;
    }).join(",");
}

export function queryParam2PersonaFiler(q: ParsedUrlQuery): PersonaFilter {
    const pf: PersonaFilter = {};
    const personaQ = (q.persona ?? "") as string;

    if (personaQ !== "") {
        pf.filter = personaQ.split(",").reduce(
            (acc: Record<string, boolean>, k: string) => {
                if (k.startsWith("~")) {
                    acc[k] = false;
                } else {
                    acc[k] = true;

                }
                return acc;
            }, {})
    }
    return pf;
}