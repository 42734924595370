import uiTrigger from "@/store/trigger/ui";
import { StoreTy } from "@/store/ty";

export const setupTrigger = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => {
  return {
    ui: { ...uiTrigger(updateStream, stateStream) },
  };
};
